import React from 'react'

const Footer = () => {
    return (
        <>
            {/* FOOTER */}
            <div id="footer">
        © 2019 haidershan1805@gmail.com | All Rights Reserved |  <a href="" style={{color: '#fff'}} target="_blank"> Designed By Haider SOft</a>
      </div>
        </>
    )
}

export default Footer;
